(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

module.exports = function () {

    var WidgetHelpers = {};

    WidgetHelpers.attributes = ['fullname', 'phone', 'email', 'prefix', 'address', 'zip', 'zip5', 'source', 'action'];
    WidgetHelpers.numofwidgets = 0;
    WidgetHelpers.testShortUrl = true;

    /**
     * Build a query string from an object
     *
     * @param {object} obj
     * @return {string}
     */
    WidgetHelpers.serialize = function (obj) {

        var str = [];

        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }

        return str.join("&");
    };

    // ---

    /**
     * Get values from the parent query string and other
     * attributes from the parent
     *
     * @param {object} parentelement
     * @return {string}
     */
    WidgetHelpers.getQuery = function (parentelement) {

        var data = {
            parentPage: null
        };

        // iterate through all the targe attributes, and if the attribute
        // is set on the parent, grab its value
        for (var x = 0; x < WidgetHelpers.attributes.length; x++) {

            var attrKey = WidgetHelpers.attributes[x];

            var value = parentelement.getAttribute('data-' + attrKey);

            if (value != null) {

                // re-key the address and zip attributes
                if (attrKey == 'address') {
                    data['address1'] = value;
                }

                if (attrKey == 'zip') {
                    data['zip5'] = value;
                }

                data[attrKey] = value;
            }
        }

        // Get Background color if it is set for the parent element
        var containerbgcolor = parentelement.style.backgroundColor;
        if (containerbgcolor) {
            data.bgcolor = WidgetHelpers.widgetrgb2hex(containerbgcolor);
        }

        //Get Parent Referrer
        if (window.btoa) {
            data.parentPage = btoa(window.location.href.replace(/.*?:\/\//g, ""));
        }

        return WidgetHelpers.serialize(data);
    };

    // ---

    /**
     * Get the source URL info from the parent
     *
     * @param {object} parent
     * @return {array}
     */
    WidgetHelpers.getParameters = function (parent) {

        var params = [];

        var shorturl = parent.getAttribute('data-shorturl');
        var domain = parent.getAttribute('data-domain');

        if (shorturl == null) {
            shorturl = '';
        }

        if (domain == null) {
            domain = 'p2a.co';
        }

        params.push(shorturl);
        params.push(domain);

        return params;
    };

    // ---

    /**
     * Take an RGB color value and bounce it to HEX
     *
     * @param {string} rgb
     * @return {string}
     */
    WidgetHelpers.widgetrgb2hex = function (rgb) {

        // if the value seems to already be in HEX, we're done
        if (/^#[0-9A-F]{6}$/i.test(rgb)) {
            return rgb;
        }

        rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

        var hex = function hex(x) {
            return ("0" + parseInt(x, 10).toString(16)).slice(-2);
        };

        return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
    };

    // ---

    /**
    * Build the properties of and inject a new iframe into the DOM
    *
    * @param object DOM element
    * @param string
    * @param string
    * @return void
    */
    WidgetHelpers.addIframe = function (parent, shorturl, domain, widgetSettings) {
        // set a flag indicating responsive behavior for the widget
        var isResponsive = typeof parent.attributes['data-responsive'] !== 'undefined' && (parent.attributes['data-responsive'].nodeValue == 'yes' || parent.attributes['data-responsive'].nodeValue == 'true');

        WidgetHelpers.numofwidgets++;

        // check for a valid-looking short url
        if (typeof parent.style.overflowY === 'undefined' || !parent.style.overflowY) {
            parent.style.overflowY = 'hidden';
        }

        parent.style.webkitOverflowScrolling = 'touch';
        parent.style.lineHeight = 0;

        // create iframe DOM element
        var frame = document.createElement('iframe');

        var frameId = Math.random().toString(36).substring(7);

        // define iFrame source
        var frameSource = '';
        if (typeof parent.attributes['data-secure'] !== 'undefined') {
            frameSource = 'https:';
        }
        frameSource += '//' + domain + widgetSettings.endpoint + shorturl + '?' + WidgetHelpers.getQuery(parent) + '&frameId=' + frameId;
        frame.src = frameSource;

        // build a unique element id (to handle multiple embeds on a single page)
        frame.id = 'embed-frame-' + frameId;

        frame.className = widgetSettings.class;
        frame.style.width = '100%';

        var parentheight = parent.style.height;

        // if the parent has a defined height, use it for the iframe
        if (typeof parentheight !== 'undefined' && parentheight) {
            // set the iframe height by evaluating the parent's height
            frame.style.height = parseInt(parentheight, 10) + 'px';
        } else {
            // otherwise, use the "auto" approach, by sizing the iframe
            // to the height of the iframe content
            frame.style.height = '100%';
        }

        frame.style.minHeight = widgetSettings.minHeight;

        frame.frameBorder = '0';

        // don't allow any scrolling on the iframe if in responsive
        // mode, as we should be resizing to the iframe content height
        if (isResponsive) {
            frame.scrolling = 'no';
        }

        // inject into the DOM
        parent.appendChild(frame);

        // use iframeResizer if needed
        if (typeof WidgetHelpers.iframeResizer === 'function') {
            WidgetHelpers.iframeResizer(frame.id);
        }

        // ---

        function setAeroStyling(eventData) {
            if (eventData.templateId && eventData.templateId === 6) {
                parent.style.borderRadius = "8px";
                parent.style.border = "1px solid rgba(0, 0, 0, 0.5)";
                parent.style.boxShadow = "0 0 6px rgba(0,0,0,0.8)";
            }
        }

        /**
         * Handle an incoming event from the iframe window
         * @param {object} event
         * @return {void}
         */
        var onMessage = function onMessage(event) {

            // ensure we are dealing with events from the widget iframe window
            if (event.origin.indexOf('//' + domain) === -1) {
                return;
            }
            // check for valid json
            try {
                var eventData = JSON.parse(event.data);
                setAeroStyling(eventData);
            } catch (e) {
                // do not proceed if event.data is not valid Json
                return;
            }

            // if we do not get the "responsive" flag from the event, we
            // do not want to alter the iframe dimensions
            if (!isResponsive) {
                return;
            }

            if (typeof eventData.frameId !== 'undefined' && eventData.frameId !== frameId) {
                return;
            }
            // set the height of the iframe to the same height as its content
            frame.style.height = eventData.height + 'px';
        };

        // setup a listener for an incoming message
        if (window.addEventListener) {
            window.addEventListener('message', onMessage, false);
        } else if (window.attachEvent) {
            window.attachEvent('onmessage', onMessage);
        }
    };

    // ---

    /**
     * @return {void}
     */
    WidgetHelpers.inheritMethods = function (widgetSettings) {

        var overrideableFunctions = ['getQuery', 'addIframe'];

        for (var func in overrideableFunctions) {
            if (typeof widgetSettings[func] != "undefined") {
                WidgetHelpers[func] = widgetSettings[func];
            }
        }
    };

    // ---

    /**
     * @return {boolean}
     */
    WidgetHelpers.testParameters = function (shorturl, domain) {

        if (WidgetHelpers.testShortUrl !== false) {
            return shorturl !== undefined && shorturl.length > 0 && shorturl.length <= 10 && domain !== undefined && domain.length > 0 && /^[a-z\d]+$/i.test(shorturl);
        }

        return true;
    };

    // ---

    WidgetHelpers.initWidgets = function (widgetSettings) {

        WidgetHelpers.inheritMethods(widgetSettings);

        //First check if any classes are out there to set the widget on
        var classwidgetsfound = false;

        //Support older browser class selection: http://stackoverflow.com/a/3808886/761793
        var re = new RegExp("(?:^|\\s)" + widgetSettings.name + "(?!\\S)"),
            elems = document.getElementsByTagName('*'),
            i,
            matchedelements = [];

        for (i in elems) {
            if (typeof elems[i] !== 'undefined' && elems[i] !== null && re.test(elems[i].className) && matchedelements.indexOf(elems[i]) === -1) {

                matchedelements.push(elems[i]);

                var parentelement = elems[i];
                var shorturl, domain;

                var _WidgetHelpers$getPar = WidgetHelpers.getParameters(parentelement);

                var _WidgetHelpers$getPar2 = _slicedToArray(_WidgetHelpers$getPar, 2);

                shorturl = _WidgetHelpers$getPar2[0];
                domain = _WidgetHelpers$getPar2[1];


                if (WidgetHelpers.testParameters(shorturl, domain)) {
                    WidgetHelpers.addIframe(parentelement, shorturl, domain, widgetSettings);
                    classwidgetsfound = true;
                }
            }
        }

        if (classwidgetsfound == false) {
            WidgetHelpers.initById(widgetSettings);
        }

        // Fire an event that our iframes exist in the dom now
        var event = new CustomEvent("advocacyWidgetCreated");
        document.dispatchEvent(event);
    };

    // ---

    WidgetHelpers.initById = function (widgetSettings) {

        var shorturl, domain;
        var parentelement = document.getElementById(widgetSettings.name);

        var _WidgetHelpers$getPar3 = WidgetHelpers.getParameters(parentelement);

        var _WidgetHelpers$getPar4 = _slicedToArray(_WidgetHelpers$getPar3, 2);

        shorturl = _WidgetHelpers$getPar4[0];
        domain = _WidgetHelpers$getPar4[1];


        if (WidgetHelpers.testParameters) {
            WidgetHelpers.addIframe(parentelement, shorturl, domain, widgetSettings);
        }
    };

    // ---

    return WidgetHelpers;
}();

},{}],2:[function(require,module,exports){
'use strict';

(function () {
  var WidgetHelpers = require('../shared.js');

  var AdvocacyWidget = {
    'name': 'advocacy-actionwidget',
    'class': 'advocacy-actionwidget-iframe',
    'endpoint': '/embed/widget/app/',
    'minHeight': '409px'
  };

  WidgetHelpers.initWidgets(AdvocacyWidget);
})();

},{"../shared.js":1}]},{},[2]);
